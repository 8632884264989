import '../css/style.scss';

window.onload = function() {

    let length = model.slide.items.length;
    var currentIndex = 0;
    for(var i=0; i < length; i++) {
        let v = model.slide.items[i];
        let element = $('<img />');
        element.attr('src', v.src);
        $('.slideshow > .contents > .items').append(element);
        $('.slideshowIndex').append('<p></p>');
    }

    var updateSlideIndex = function() {
        $('.slideshowIndex > p').each(function(index) {
            $(this).removeClass('active');
        });
        $('.slideshowIndex > p').eq(currentIndex).addClass('active');
        $('.slideshow > .contents > .title').text(model.slide.items[currentIndex].title);
    };
    updateSlideIndex();

    var changeSlideIndex = function() {
        $('.slideshow > .contents > .items').stop().animate({left: currentIndex * -720});
        updateSlideIndex();
    }

    $('.slideshow > .right').on('click', function() {
        if(currentIndex >= length-1)
            currentIndex = -1;
        currentIndex++;
        changeSlideIndex();
    });
    $('.slideshow > .left').on('click', function() {
        if(currentIndex <= 0)
            currentIndex = length;
        currentIndex--;
        changeSlideIndex();
    });

    $('.slideshowIndex > p').on('click', function() {
        currentIndex = $(this).index();
        changeSlideIndex();
    });

    let usecasesLength = model.usecases.length;
    for(var i=0; i < usecasesLength; i++) {
        let v = model.usecases[i];
        let element = $('<div class="card"></div>');
        let img = $('<img />');
        let h1 = $('<h1></h1>');
        let p = $('<p></p>');

        img.attr('src', v.src);
        h1.text(v.title);
        p.text(v.text);

        element.append(img);
        element.append(h1);
        element.append(p);

        $('#usecases > .container > .cards').append(element);
    }

    $(function(){
        $('a[href^="#"]').click(function(){
            var adjust = 0;
            var speed = 800;
            var href= $(this).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top + adjust;
            $('body,html').animate({scrollTop:position}, speed, 'swing');
            return false;
        });
    });
   
    $('body').css('visibility', 'visible');

    $('.logo > img').on('mouseover', function(){
        $(this).attr('src', 'lp_asset/img/logo_hover.svg');
    });

    $('.logo > img').on('mouseout', function(){
        $(this).attr('src', 'lp_asset/img/logo.svg');
    });
}
